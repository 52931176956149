.navItems {
    @apply flex gap-2 items-center;
}

.navSearcWrapper {
    width: 250px;
    @apply rounded-full bg-primary-tag border-primary-tag outline-none;

    * {
        @apply bg-primary-tag;
    }
}

@primary-color: #EE3257;@text-color: #9AA7B4;@link-color: #EE3257;