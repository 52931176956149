.svgLink {
    @apply h-12 flex  justify-center items-center border-secondary-dark  relative overflow-hidden;

    &::before {
        @apply transition-all;
        content: " ";
    }

    path,
    rect {
        fill: #c4c4c4;
        transition: all;
    }

    // &:hover,
    &.active {
        &::before {
            width: 7px;
            @apply bg-primary-red rounded-md h-full absolute -left-1;
        }

        path,
        rect {
            fill: #ffffff;
        }
    }

    &:not(.active) {
        margin: auto 1rem;
    }

    &:hover:not(.active) {
        @apply rounded-md;
        margin: auto 1rem;
        background-color: #191f25;
    }
}

.footerLinkActive {
    @apply text-primary-white relative;
    &::after {
        content: " ";
        width: 8px;
        height: 8px;
        @apply bg-primary-red rounded-full absolute block left-2/4 transform -translate-x-2/4 mt-1;
    }
}

.footerLink {
    &:hover {
        @apply text-primary-white relative;
        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            @apply bg-primary-red rounded-full absolute block left-2/4 transform -translate-x-2/4 mt-1;
        }
    }
}

@primary-color: #EE3257;@text-color: #9AA7B4;@link-color: #EE3257;