.dashboardItem {
    @apply w-2/4  rounded px-4 py-2;
}

.previewItem {
    @apply text-primary-white text-xl;
}

.contentItem {
    // border: 4px solid transparent;

    background: transparent;

    .hoverItem {
        visibility: hidden;
    }

    .overlay {
        @apply absolute left-0 top-0 w-full h-full;

        @apply rounded-md;

        background: linear-gradient(
            180deg,
            rgba(36, 123, 160, 0.6) 0%,
            rgba(36, 123, 160, 0.8) 100%
        );

        display: none;
    }

    &:hover {
        // border: 4px solid rgba(255, 255, 255, 0.4);

        .hoverItem {
            visibility: visible;
        }

        .overlay {
            display: block;
        }
    }
}

.channelItem {
    @apply cursor-pointer bg-center bg-no-repeat rounded-md bg-cover;

    .channelItemWrapper {
        @apply grid place-items-center  rounded-md transition-all duration-200;

        &:hover {
            @apply shadow-2xl;
            background: transparent;
            border-color: #fff;
        }
    }

    .overlay {
        @apply absolute left-0 top-0 w-full h-full;

        background: linear-gradient(
            180deg,
            rgba(36, 123, 160, 0.6) 0%,
            rgba(36, 123, 160, 0.8) 100%
        );
    }

    &:hover {
        .overlay {
            display: none;
        }
    }
}

.featuredHeight {
    height: 80vh;
    @media screen and (max-width: 767px) {
        height: 50vh; // Adjust the height for smaller screens
    }
    
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 65vh; // Adjust the height for medium-sized screens
    }
}

.channelBox {
    @apply m-0 transition-all bg-tertiary-dark rounded-lg grid place-items-center text-center;
    &:hover {
        @apply m-0;

        .secondary-button {
            @apply bg-primary-tag-hover;
        }
    }
}

.portraitContentWrapper {
    @apply py-6;
    &:hover {
        .portraitCoverItem {
            top: -24px;
            height: "calc(100% + 40px)";
        }
    }
}

.redPlayIcon {
    path {
        fill: #ee3257;
    }
}

@primary-color: #EE3257;@text-color: #9AA7B4;@link-color: #EE3257;