.coverOverlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, #000000 100%);
}
.playButtonClass {
    background: transparent;
    display: inline-block;
    padding: 25px;
    position: absolute;    
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        height: 50px;
        width: 50px;
    }
}

.border {
    border: 5px solid rgba(255, 255, 255, 0.4);
}

.previewItem {
    &:hover {
        path {
            fill: #fff !important;
        }
    }
}

.previewItemWatch {
    fill: red;
}

@primary-color: #EE3257;@text-color: #9AA7B4;@link-color: #EE3257;